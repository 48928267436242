
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationDocMixin from '@/mixins/data-operations/doc/configuration-doc-mixin';

import { vmLauncherConfs } from '@/store/modules/easy-firestore/vm-launcher-confs';
import { vmLauncherConfArchive } from '@/store/modules/easy-firestore/vm-launcher-conf-archive';

@Component
export default class VmLauncherConfigurationsItemView extends Mixins(HeaderInfosMixin, ConfigurationDocMixin) {
	moduleName: string = vmLauncherConfs.moduleName;
	archivedConfsModuleName: string = vmLauncherConfArchive.moduleName;

	get configurationData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: this.item.id,
					viewType: 'conf',
					showDagLaunch: true,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the VM Launcher configuration',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{ id: 'client_type', label: 'Client Type', value: this.getFormattedClientType(this.item.client_type) },
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.client_version),
						},
					],
				},
			},
		];
	}
}
