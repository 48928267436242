
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';

import { vmLauncherConfs } from '@/store/modules/easy-firestore/vm-launcher-confs';
import { VM_LAUNCHER_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIONS,
	ACTIVATED,
	CONF_CLIENT_VERSION,
	ENVIRONMENT,
	GCS_FILE_EXCHANGE_BUCKET,
	ID,
	WORKING_DIR,
} from '@/constants/data-operations/listing/header-items';

@Component
export default class VmLauncherConfigurationsListingView extends Mixins(
	HeaderInfosMixin,
	ConfigurationCollectionMixin
) {
	moduleName: string = vmLauncherConfs.moduleName;
	overriddenColumns: string[] = ['id'];

	get routeName() {
		return VM_LAUNCHER_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [ENVIRONMENT, CONF_CLIENT_VERSION, ID, GCS_FILE_EXCHANGE_BUCKET, WORKING_DIR, ACTIVATED, ACTIONS];
	}
}
